import { ref, useContext } from '@nuxtjs/composition-api'
import { getNextYearDate } from '~/lib/utilities/datetime'
import { PermissionStatus } from '~/types/common'

const COOKIE_PERMISSION_KEY = 'binorm_cookieconsent_status'

export function useCookiePermission() {
  const {
    app: { $storage },
  } = useContext()

  const visible = ref(false)

  const setCookie = (permission: PermissionStatus) => {
    $storage.setCookie(COOKIE_PERMISSION_KEY, permission, {
      path: '/',
      expires: getNextYearDate(),
    })
  }

  const getPermissionStatus = (): PermissionStatus => {
    return $storage.getCookie(COOKIE_PERMISSION_KEY) || PermissionStatus.Prompt
  }

  const open = () => {
    visible.value = true
  }

  const close = () => {
    visible.value = false
  }

  const onAccept = () => {
    setCookie(PermissionStatus.Granted)
    close()
  }

  const onRefuse = () => {
    setCookie(PermissionStatus.Denied)
    close()
  }

  return {
    visible,
    getPermissionStatus,
    open,
    close,
    onAccept,
    onRefuse,
  }
}
